<template>
  <div class="relative slider-wrapper">
    <Swiper
      :modules="modules"
      :slides-per-view="props.itemsPerRowOnMobile"
      :space-between="16"
      class="pb-20"
      :scrollbar="{ el: '.swiper-scrollbar', draggable: true }"
      :breakpoints="{
        '768': {
          slidesPerView: 3.2,
          spaceBetween: 20,
          centeredSlidesBounds: false,
          centerInsufficientSlides: false,
          slidesPerGroup: 4.4,
          slidesOffsetBefore: 0,
        },
        '1024': {
          slidesPerView: 4,
          spaceBetween: 20,
          centeredSlidesBounds: false,
          centerInsufficientSlides: false, // !!(props.alignment === 'center'),
          slidesPerGroup: 1,
          slidesOffsetBefore: 0,
        },
        '1280': {
          slidesPerView: props.itemsPerRowOnDesktop,
          spaceBetween: 20,
          centeredSlidesBounds: false,
          centerInsufficientSlides: false,
          slidesPerGroup: 1,
          slidesOffsetBefore: 0,
        },
      }"
    >
      <SwiperSlide v-for="item in items" :key="item.id" class="swiper-slide">
        <slot :item="item" />
      </SwiperSlide>
      <SwiperControls :current-index="currentIndex" @change="(index: number) => (currentIndex = index)" />
    </Swiper>
    <!-- If we need navigation buttons -->
    <button
      v-if="items.length > itemsPerRowOnDesktop"
      class="swiper-nav swiper-button-prev"
      :class="{
        'swiper-nav__inverted': inverted,
      }"
      aria-label="previous slide"
      @click="prevSlide"
    >
      <svg-icon-chevron-left class="w-10 h-10" :class="[$i18n.locale == 'ar' ? 'transform rotate-180' : '']" />
    </button>
    <button
      v-if="items.length > itemsPerRowOnDesktop"
      class="swiper-nav swiper-button-next"
      :class="{
        'swiper-nav__inverted': inverted,
      }"
      aria-label="next slide"
      @click="nextSlide"
    >
      <svg-icon-chevron-right class="w-10 h-10" :class="[$i18n.locale == 'ar' ? 'transform rotate-180' : '']" />
    </button>
  </div>
</template>

<script setup lang="ts">
import { Navigation, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/vue';
const modules = [Navigation, Autoplay];
const props = defineProps({
  items: {
    type: Array as PropType<ReturnType<typeof mapProductListing>[]>,
    required: true,
  },
  arrows: {
    type: Boolean,
    default: false,
  },
  itemsPerRowOnDesktop: {
    type: Number,
    default: 5,
  },
  itemsPerRowOnMobile: {
    type: Number,
    default: 2.4,
  },
  alignment: {
    type: String,
    default: 'start',
    validator: (value: string) => ['start', 'center', 'end'].includes(value),
  },
  inverted: {
    type: Boolean,
    default: false,
  },
});

const currentIndex = ref(0);

function nextSlide() {
  currentIndex.value = getValidValue(currentIndex.value + props.itemsPerRowOnDesktop);
}

function prevSlide() {
  currentIndex.value = getValidValue(currentIndex.value - props.itemsPerRowOnDesktop);
}

function getValidValue(num: number) {
  return clamp(0, props.items.length - 1, num);
}
</script>

<style lang="postcss" scoped>
.swiper,
.swiper-container {
  width: 100%;
}
.slider-wrapper {
  @screen lg {
    /* prevent buttons overflow on some screens */
    width: calc(100% - 140px);
    margin: 0 auto;
  }
}
.swiper-nav {
  @apply hidden lg:flex;
  width: 40px;
  height: 40px;
  &::after {
    content: unset;
  }
  &__inverted {
    @apply border-white;
    svg {
      @apply text-white;
    }
  }
  &.swiper-button-next {
    position: absolute;
    right: -70px;
  }
  &.swiper-button-prev {
    position: absolute;
    left: -70px;
  }
  &.swiper-button-next,
  &.swiper-button-prev {
    top: 40%;
  }
  &.swiper-button-disabled {
    opacity: 0.3;
  }
}

.swiper-container-horizontal > .swiper-scrollbar {
  position: absolute;
  bottom: 3px;
  left: 5%;
  z-index: 50;
  height: 2px;
  width: 98%;

  @media (min-width: 1024px) {
    width: 100%;
    left: 0;
  }
}
</style>
<style>
.swiper-scrollbar-drag {
  background: black !important;
}
</style>
